import { Navigate } from "react-router-dom";

import React, { ReactNode, useContext } from "react";
import { AuthContext } from "../context.tsx/auth/context";

const RequireAuth = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated === true ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <Navigate to={"/auth/login"} replace />
  );
};
export default RequireAuth;
