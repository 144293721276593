import TabPane from "antd/es/tabs/TabPane";
import "./App.css";
import { DatePicker, Tabs, notification } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import CompinedReports from "./components/CompinedReports";
import RatingsSection from "./sections/RatingsSection";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import RequireAuth from "./router/RequireAuth";
import BaseLayout from "./pages/BaseLayout";

const { RangePicker } = DatePicker;
function App() {
  const protectedLayout = (
    <RequireAuth>
      <BaseLayout />
    </RequireAuth>
  );

  return (
    <>
      <Routes>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/" element={protectedLayout} />
      </Routes>
    </>
  );
}

export default App;
