import { DatePicker, Tabs, notification } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import TabPane from "antd/es/tabs/TabPane";
import CompinedReports from "../components/CompinedReports";

const { RangePicker } = DatePicker;

const BaseLayout = () => {
  const [selectedDateRange, setSelectedDateRange] = useState(undefined);
  const [linkersData, setLinkersData] = useState(null);
  const [linkersLoading, setLinkersLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLinkersLoading(true);
        let start_date =
          selectedDateRange[0].format("YYYY-MM-DD") + " 00:00:00";

        let end_date = selectedDateRange[1].format("YYYY-MM-DD") + " 23:59:59";

        const params = {
          include: "branch",
          "filter[date_time_start]": start_date,
          "filter[date_time_end]": end_date,
        };

        const url = "https://cxai-api.linkers.io/api/v0.1/people/counter/daily";
        const headers = {
          Authorization: "Bearer " + process.env.REACT_APP_LINKERS_ACCESS_TOKEN,
          Accept: "application/json",
        };

        const response = await axios.get(url, { headers, params });
        let allData = [];

        for (let i = 0; i < response.data.data.length; i++) {
          const item = response.data.data[i];
          // Check if the branch ID already exists in allData
          let existingItem = allData.find(
            (data) => data.branch.id === item.branch.id
          );

          if (existingItem) {
            // The branch ID already exists in allData, so update the attributes
            existingItem.ValueIn += item.ValueIn;
            existingItem.TransactionCount += item.TransactionCount;
          } else {
            // The branch ID does not exist in allData, so append the item as usual
            allData.push(item);
          }
        }
        setLinkersData(allData);
      } catch (error) {
        console.error("Error fetching data:", error);
        notification.error({ message: "Error getting data from linkers" });
      } finally {
        setLinkersLoading(false);
      }
    };

    if (selectedDateRange) fetchData();
  }, [selectedDateRange]);

  // Sales report logic
  const [allData, setAllData] = useState([]);
  const [salesLoading, setSalesLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setSalesLoading(true);
        let start_date =
          selectedDateRange[0].format("YYYY-MM-DD") + "T00:00:00.000Z";
        let end_date =
          selectedDateRange[1].format("YYYY-MM-DD") + "T23:59:59.999Z";

        const url = "https://balsam.two-cholding.com/get_sales_report";
        // const url = "https://balsam.pythonanywhere.com/get_sales_report";

        const response = await axios.post(url, { start_date, end_date });
        const data = response.data.data;
        let allDatas = [];
        for (let i = 0; i < response.data.data.length; i++) {
          const item = response.data.data[i];
          let existingItem = allDatas.find(
            (data) => data.branch === item.branch
          );
          if (existingItem) {
            // The branch ID already exists in allData, so update the attributes
            existingItem.total_revenue += item.total_revenue;
            existingItem.item_count += item.item_count;
            existingItem.sale_count += item.sale_count;
          } else {
            // The branch ID does not exist in allData, so append the item as usual
            allDatas.push(item);
          }
        }
        setAllData(allDatas);
      } catch (error) {
        console.error("Error fetching data:", error);
        notification.error({
          message: "Error getting data from pythonanywhere",
        });
      } finally {
        setSalesLoading(false);
      }
    };

    if (selectedDateRange) fetchData();
  }, [selectedDateRange]);

  // Ratings Data
  const [allRatingsData, setAllRatingsData] = useState([]);
  const [ratingsLoading, setRatingsLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        let start_date =
          selectedDateRange[0].format("YYYY-MM-DD") + "T00:00:00.000Z";
        let end_date =
          selectedDateRange[1].format("YYYY-MM-DD") + "T23:59:59.999Z";

        setRatingsLoading(true);
        const reefiUrl = "https://reefi.two-cholding.com/get_ratings";
        const balsamUrl = "https://balsam.two-cholding.com/get_ratings";

        const reefiResponse = await axios.post(reefiUrl, {
          start_date,
          end_date,
        });
        const balsamResponse = await axios.post(balsamUrl, {
          start_date,
          end_date,
        });

        setAllRatingsData([
          ...reefiResponse.data.data,
          ...balsamResponse.data.data,
        ]);
      } catch (err) {
      } finally {
        setRatingsLoading(false);
      }
    };

    if (selectedDateRange) getData();
  }, [selectedDateRange]);

  return (
    <>
      <div className="container">
        <RangePicker
          onChange={(val) => setSelectedDateRange(val)}
          disabled={linkersLoading || salesLoading}
        />
        <Tabs>
          <TabPane tab={"La Belle"} key={0}>
            <CompinedReports
              linkerData={linkersData?.filter((item) =>
                item.branch.name.includes("La Belle")
              )}
              lightspeedData={allData?.filter((item) =>
                item.branch.includes("La Belle")
              )}
              ratingsData={allRatingsData}
              linkerloading={linkersLoading}
              lightspeedLoading={salesLoading}
            />
          </TabPane>
          <TabPane tab={"Reefi"} key={1}>
            <CompinedReports
              linkerData={linkersData?.filter(
                (item) => !item.branch.name.includes("La Belle")
              )}
              lightspeedData={allData?.filter(
                (item) => !item.branch.includes("La Belle")
              )}
              linkerloading={linkersLoading}
              lightspeedLoading={salesLoading}
              ratingsData={allRatingsData}
              ratingLoading={ratingsLoading}
            />
          </TabPane>
          <TabPane key={2} tab="All Data">
            <CompinedReports
              linkerData={linkersData}
              lightspeedData={allData}
              linkerloading={linkersLoading}
              lightspeedLoading={salesLoading}
              ratingsData={allRatingsData}
            />
          </TabPane>

          {/* <TabPane tab={"Sales Report"} key="0">
    <Tabs type="card">
      <TabPane tab={"La Belle"} key={0}>
        <SalesReport
          data={allData?.filter((item) =>
            item.branch.includes("La Belle")
          )}
          // data={allData}
          loading={salesLoading}
        />
      </TabPane>
      <TabPane tab="Reefi" key={1}>
        <SalesReport
          data={allData?.filter(
            (item) => !item.branch.includes("La Belle")
          )}
          // data={allData}
          loading={salesLoading}
        />
      </TabPane>
    </Tabs>
  </TabPane>
  <TabPane tab={"Linker Data"} key="1">
    <Tabs type="card">
      <TabPane tab="La Belle" key={0}>
        <LinkersReport
          data={linkersData?.filter((item) =>
            item.branch.name.includes("La Belle")
          )}
          loading={linkersLoading}
        />
      </TabPane>
      <TabPane tab="Reefi" key={1}>
        <LinkersReport
          data={linkersData?.filter(
            (item) => !item.branch.name.includes("La Belle")
          )}
          loading={linkersLoading}
        />
      </TabPane>
    </Tabs>
  </TabPane>
  <TabPane tab={"Compined Data"} key="2">
    <Tabs type="card">
      <TabPane tab={"La Belle"} key={0}>
        <CompinedReports
          linkerData={linkersData?.filter((item) =>
            item.branch.name.includes("La Belle")
          )}
          lightspeedData={allData?.filter((item) =>
            item.branch.includes("La Belle")
          )}
          linkerloading={linkersLoading}
          lightspeedLoading={salesLoading}
        />
      </TabPane>
      <TabPane tab={"Reefi"} key={1}>
        <CompinedReports
          linkerData={linkersData?.filter(
            (item) => !item.branch.name.includes("La Belle")
          )}
          lightspeedData={allData?.filter(
            (item) => !item.branch.includes("La Belle")
          )}
          linkerloading={linkersLoading}
          lightspeedLoading={salesLoading}
        />
      </TabPane>
      <TabPane key={2} tab="All Data">
        <CompinedReports
          linkerData={linkersData}
          lightspeedData={allData}
          linkerloading={linkersLoading}
          lightspeedLoading={salesLoading}
        />
      </TabPane>
    </Tabs>
  </TabPane> */}
        </Tabs>
      </div>
    </>
  );
};

export default BaseLayout;
