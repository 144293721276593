import React, { useEffect, useState } from "react";
import { Table } from "antd";

const CompinedReports = ({
  linkerData,
  lightspeedData,
  linkerloading,
  lightspeedLoading,
  ratingsData,
  ratingLoading,
}) => {
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    const compineData = () => {
      let compinedData = [];
      for (let i = 0; i < lightspeedData.length; i++) {
        const item = lightspeedData[i];
        console.log("ss", linkerData[i]);

        let existingItem = linkerData.find(
          (data) => data.branch.name === item.branch
        );
        if (existingItem) {
          const mergedItem = { ...existingItem, ...item };
          console.log(mergedItem);

          // Check if ratingsData exists and is not empty
          if (ratingsData && ratingsData.length > 0) {
            const ratingInfo = ratingsData.find(
              (data) => data.branch_name === mergedItem.branch
            );
            if (ratingInfo) {
              mergedItem.rating_count = ratingInfo.rating_count;
              mergedItem.total_rating_value = ratingInfo.total_rating_value;
            }
          }
          compinedData.push(mergedItem);
        }
      }
      setAllData(compinedData);
      console.log("All Data", compinedData);
    };

    if (linkerData && lightspeedData) compineData();
  }, [linkerData, lightspeedData, ratingsData]);

  const columns = [
    {
      title: "Branch",
      dataIndex: "branch",
      align: "center",
      key: "branch",
      sorter: {
        compare: (a, b) => a.branch.localeCompare(b.branch),
        order: "ascend",
        multiple: 1,
      },
    },
    {
      title: "Total Revenue",
      dataIndex: "total_revenue",
      align: "center",
      key: "total_revenue",
      sorter: {
        compare: (a, b) => a.total_revenue - b.total_revenue,
        multiple: 2,
      },
      render: (val) => {
        return <>{val.toFixed(1)}</>;
      },
    },
    {
      title: "Average Items Per Sale",
      dataIndex: "",
      align: "center",
      key: "avgitemspersale",
      sorter: {
        compare: (a, b) => {
          const avgA =
            a.sale_count > 0 ? (a.item_count / a.sale_count).toFixed(1) : 0;
          const avgB =
            b.sale_count > 0 ? (b.item_count / b.sale_count).toFixed(1) : 0;
          return parseFloat(avgA) - parseFloat(avgB);
        },
        multiple: 3,
      },
      render: (_, record) => {
        return (
          <>
            {record.sale_count > 0
              ? (record.item_count / record.sale_count).toFixed(1)
              : "No sales"}
          </>
        );
      },
    },
    {
      title: "Average Sale Value",
      dataIndex: "",
      align: "center",
      key: "body",
      sorter: {
        compare: (a, b) => {
          const avgA =
            a.sale_count > 0 ? (a.total_revenue / a.sale_count).toFixed(2) : 0;
          const avgB =
            b.sale_count > 0 ? (b.total_revenue / b.sale_count).toFixed(2) : 0;
          return parseFloat(avgA) - parseFloat(avgB);
        },
        multiple: 4,
      },
      render: (_, record) => {
        return (
          <>
            {record.sale_count > 0
              ? (record.total_revenue / record.sale_count).toFixed(2)
              : "No sales"}
          </>
        );
      },
    },
    {
      title: "Visitors",
      dataIndex: "ValueIn",
      align: "center",
      key: "ValueIn",
      sorter: {
        compare: (a, b) => a.ValueIn - b.ValueIn,
        multiple: 2,
      },
    },

    {
      title: "Customers",
      dataIndex: "TransactionCount",
      align: "center",
      key: "TransactionCount",
      sorter: {
        compare: (a, b) => a.TransactionCount - b.TransactionCount,
        multiple: 3,
      },
    },

    // {
    //   title: "Outside Traffic",
    //   dataIndex: "OutsideTraffic",
    //   align: "center",
    //   key: "OutsideTraffic",
    //   sorter: {
    //     compare: (a, b) => a.OutsideTraffic - b.OutsideTraffic,
    //     multiple: 5,
    //   },
    // },
    {
      title: "Turn in Rate",
      dataIndex: "TurnInRate",
      align: "center",
      key: "TurnInRate",
      sorter: {
        compare: (a, b) => a.TurnInRate - b.TurnInRate,
        multiple: 6,
      },
      render: (val) => {
        return <>{val}%</>;
      },
    },
    {
      title: "Conversion Rate",
      dataIndex: "ConversionRate",
      align: "center",
      key: "ConversionRate",
      sorter: (a, b) => {
        const rateA = parseFloat(
          a.ValueIn > 0 ? (a.TransactionCount / a.ValueIn).toFixed(1) : "0"
        );
        const rateB = parseFloat(
          b.ValueIn > 0 ? (b.TransactionCount / b.ValueIn).toFixed(1) : "0"
        );

        if (rateA > rateB) {
          return -1;
        }
        if (rateA < rateB) {
          return 1;
        }
        return 0;
      },
      render: (_, record) => {
        return (
          <>
            {record.ValueIn > 0
              ? (record.TransactionCount / record.ValueIn).toFixed(2)
              : 0}
            %
          </>
        );
      },
    },

    {
      title: "Total Rating Value",
      dataIndex: "total_rating_value",
      key: "total_rating_value",
      align: "center",
      render: (val) => {
        return <>{val ? val : "-"}</>;
      },
    },
    {
      title: "Ratings Count",
      dataIndex: "rating_count",
      key: "rating_count",
      align: "center",
      render: (val) => {
        return <>{val ? val : "-"}</>;
      },
    },
    {
      title: "Average Ratings",
      dataIndex: "average_rating",
      key: "average_ratings",
      align: "center",
      render: (_, record) => {
        // Check if both total_rating_value and rating_count exist
        if (
          record?.total_rating_value !== undefined &&
          record?.rating_count !== undefined
        ) {
          // Perform division and round to two decimal places
          const averageRating = (
            record.total_rating_value / record.rating_count
          ).toFixed(2);
          return <>{averageRating}</>;
        } else {
          return <>{"-"}</>;
        }
      },
    },
  ];

  return (
    <Table
      columns={columns}
      size="middle"
      loading={linkerloading || lightspeedLoading || ratingLoading}
      dataSource={allData ?? []}
      pagination={false}
      scroll={{ x: 200 }}
    />
  );
};

export default CompinedReports;
