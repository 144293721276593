import React, { useContext, useEffect } from "react";
import { Form, Input, Button, Image, Select, notification } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context.tsx/auth/context";

const Login = () => {
  const { isAuthenticated, login } = useContext(AuthContext);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onSubmit = (data) => {
    if (data.email === "admin@test.com" && data.password === "2!£11UwOP8s)") {
      login();
      navigate("/");
    } else {
      notification.error({ message: "Wrong Credintials" });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Image
          preview={false}
          src={`/logo512.png`}
          alt={"logo"}
          width={100}
          style={{ marginBottom: "1rem" }}
        />

        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          className={styles.form}
          scrollToFirstError
          validateMessages={{
            required: "This Field is Required",
          }}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              // {
              //   type: "email",
              //   message: "Please enter a valid email",
              // },
              {
                required: true,
              },
            ]}
          >
            <Input
              type="text"
              className={styles.input}
              prefix={<MailOutlined className={styles.prefixIcon} />}
              placeholder="Email"
            />
          </Form.Item>
          {/* <Form.Item
            name="type"
            label="User Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={[
                { label: "Employee", value: "employee" },
                { label: "Parent", value: "parent" },
                { label: "Teacher", value: "teacher" },
                { label: "Student", value: "student" },
              ]}
            />
          </Form.Item> */}
          <Form.Item
            style={{ marginTop: "1rem", marginBottom: "2px" }}
            label="Password"
            name="password"
            rules={[
              {
                required: true,
              },
              {
                min: 6,
                message: "Invalid password",
              },
              {
                max: 32,
                message: "Invalid Password",
              },
            ]}
          >
            <Input.Password
              className={styles.input}
              prefix={<LockOutlined className={styles.prefixIcon} />}
              placeholder="Password"
            />
          </Form.Item>
          {/* 
          <Button onClick={handleForgetPassword} type="text">
            Forget Password?
          </Button> */}

          <Form.Item
            style={{ padding: "0", margin: "0" }}
            className={styles.footer}
          >
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className={styles.submitButton}
              //   loading={loading.includes("login")}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
